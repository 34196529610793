import { Node } from '@baklavajs/core'

export class SetPriceNode extends Node {
  constructor () {
    super()
    this.type = 'SetPrice'
    this.name = 'Set Price'
    this.addInputInterface('Sets the price on true or false condition. Ex \'70% DBMarket\'', undefined, undefined, { type: 'function' })
    this.addOption('Percentage, ex 70%', 'InputOption', '')
    this.addInputInterface('Price Source', 'InputOption', undefined, { type: 'plugin' })
  }
}
