import { Node } from '@baklavajs/core'

export class CheckNode extends Node {
  type = 'Check'
  name = 'Check Plugin'

  constructor () {
    super()
    this.addOption('Description', 'TextOption', 'If A grater than 0 then return B, otherwise return C')
    this.addInputInterface('Parameter A', 'InputOption', undefined, { type: 'plugin' })
    this.addInputInterface('Parameter B', 'InputOption', undefined, { type: 'plugin' })
    this.addInputInterface('Parameter C', 'InputOption', undefined, { type: 'plugin' })
    this.addOutputInterface('Result', { type: 'plugin' })
  }
}
