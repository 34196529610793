import { Node } from '@baklavajs/core'

export class LessThanOrEqualToNode extends Node {
  constructor () {
    super()
    this.type = 'LessThanOrEqualTo'
    this.name = 'Less Than Or Equal To Function'
    this.addInputInterface('Check if A is less than or equal to B', undefined, undefined, { type: 'function' })
    this.addInputInterface('Paramater A', 'InputOption', undefined, { type: 'plugin' })
    this.addInputInterface('Paramater B', 'InputOption', undefined, { type: 'plugin' })
    this.addOutputInterface('True', { type: 'function' })
    this.addOutputInterface('False', { type: 'function' })
  }
}
