import Vue from 'vue'

export default Vue.extend({
  // eslint-disable-next-line vue/require-prop-types
  props: ['name', 'node'],
  render (h) {
    return h('button', {
      class: 'paramBttn',
      on: {
        click: () => { this.node.action(this.name) }
      }
    }, this.name)
  }
})
