import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  template: '<textarea v-model="value" class="dark-input"></textarea>'
})
export default class TextAreaNode extends Vue {
  @Prop({ type: String, default: '' })
  value

  render (h) {
    return h('textarea', {
      directives: [{ name: 'model', value: this.value, expression: 'value' }],
      staticClass: 'dark-input',
      attrs: {
        rows: '4',
        placeholder: 'Add a comment to the scene'
      },
      domProps: { value: this.value },
      on: {
        input: ($event) => { this.$emit('input', $event.target.value) }
      }
    })
  }
}
