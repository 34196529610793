import { Node } from '@baklavajs/core'

export class StartNode extends Node {
  constructor () {
    super()
    this.type = 'Start'
    this.name = 'Start'
    this.addOutputInterface('The beginning of your string', { type: 'function' })
  }
}
