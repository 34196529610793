import { Node } from '@baklavajs/core'

export class GreaterThanNode extends Node {
  constructor () {
    super()
    this.type = 'GreaterThan'
    this.name = 'Greater Than Function'
    this.addInputInterface('Check if A is greater than B', undefined, undefined, { type: 'function' })
    this.addInputInterface('Paramater A', 'InputOption', undefined, { type: 'plugin' })
    this.addInputInterface('Paramater B', 'InputOption', undefined, { type: 'plugin' })
    this.addOutputInterface('True', { type: 'function' })
    this.addOutputInterface('False', { type: 'function' })
  }
}
