import { Node } from '@baklavajs/core'

export class CommentNode extends Node {
  constructor () {
    super()
    this.type = 'Comment'
    this.name = 'Comment'
    this.addOption('', 'TextAreaOption')
  }
}
