import { render, staticRenderFns } from "./AboutTSMModal.vue?vue&type=template&id=42204c5d&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../../../usr/lib/node_modules/@vue/cli-service-global/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports