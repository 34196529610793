<template>
  <div
    v-if="active"
    class="dropdown-content"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AppDropdownContent',
  inject: ['sharedState'],
  computed: {
    active () {
      return this.sharedState.active
    }
  }
}
</script>

<style>
.dropdown-content {
  margin-top: 5px;
  position: absolute;
  left: 0;
  background: #2f343f;
  z-index: 1;
  min-width: 180px;
}
</style>
