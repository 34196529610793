<template>
  <div class="nav">
    <div class="menu">
      <AppDropdown>
        <template slot="toggler">
          <button class="menu-item">
            <span>File</span>
            <AppDropdownContent>
              <AppDropdownItem>
                <div
                  class="item"
                  @click="openNewModal()"
                >
                  New Scene
                </div>
              </AppDropdownItem>
              <AppDropdownItem>
                <label class="text-reader item">
                  Import Scene
                  <input
                    type="file"
                    accept=".json"
                    @change="importScene($event)"
                  >
                </label>
              </AppDropdownItem>
              <AppDropdownItem>
                <div
                  class="item"
                  @click="openSaveModal()"
                >
                  Save Scene
                </div>
              </AppDropdownItem>
              <AppDropdownItem>
                <label class="text-reader item">
                  Open Scene
                  <input
                    type="file"
                    accept=".json"
                    @change="openScene($event)"
                  >
                </label>
              </AppDropdownItem>
            </AppDropdownContent>
          </button>
        </template>
      </AppDropdown>

      <AppDropdown>
        <template slot="toggler">
          <button class="menu-item">
            <span>Help</span>
            <AppDropdownContent>
              <AppDropdownItem>
                <div
                  class="item"
                  @click="openWelcomeModal()"
                >
                  Welcome
                </div>
              </AppDropdownItem>
              <AppDropdownItem>
                <div
                  class="item"
                  @click="openAboutModal()"
                >
                  About TSM Editor
                </div>
              </AppDropdownItem>
              <AppDropdownItem>
                <div
                  class="item"
                  @click="openReleaseNotesModal()"
                >
                  Release Notes
                </div>
              </AppDropdownItem>
            </AppDropdownContent>
          </button>
        </template>
      </AppDropdown>

      <AppDropdown>
        <template slot="toggler">
          <button class="menu-item">
            <span>Examples</span>
            <AppDropdownContent>
              <AppDropdownItem>
                <div
                  class="item"
                  @click="openHighMovExample()"
                >
                  High Movers
                </div>
              </AppDropdownItem>
              <AppDropdownItem>
                <div
                  class="item"
                  @click="openBasicValueExample()"
                >
                  Basic High Value
                </div>
              </AppDropdownItem>
              <AppDropdownItem>
                <div
                  class="item"
                  @click="openAdvValueExampleExample()"
                >
                  Advanced High Value
                </div>
              </AppDropdownItem>
            </AppDropdownContent>
          </button>
        </template>
      </AppDropdown>
    </div>

    <a
      href="https://discord.gg/woweconomy"
      target="_blank"
      class="discord"
    >WoWEconomy Server</a>
  </div>
</template>

<script>
/** core */
import AppDropdown from './dropdown/AppDropdown.vue'
import AppDropdownContent from './dropdown/AppDropdownContent.vue'
import AppDropdownItem from './dropdown/AppDropdownItem.vue'

/** modals */
import WelcomeModal from './modals/WelcomeModal.vue'
import AboutModal from './modals/AboutTSMModal.vue'
import ReleaseNotes from './modals/ReleaseNotes.vue'
import SaveScene from './modals/SaveModal.vue'

/** examples */
import * as HighMovExample from '../examples/ExampleHighMover.json'
import * as BasicValueExample from '../examples/ExampleBasicHighValue.json'
import * as AdvValueExample from '../examples/ExampleAdvancedHighValue.json'

const emptyScene = () => window.editor.load(JSON.parse('{"nodes":[{"type":"Start","id":"node_16240438894570","name":"Start","options":[],"state":{},"interfaces":[["The beginning of your string",{"id":"ni_16240438894571","value":null}]],"position":{"x":400,"y":140},"width":200,"twoColumn":false}],"connections":[],"panning":{"x":0,"y":0},"scaling":1}'))

export default {
  name: 'App',
  components: {
    AppDropdown,
    AppDropdownContent,
    AppDropdownItem
  },
  created () {
    document.addEventListener('keydown', function (e) {
      if ((e.metaKey || e.ctrlKey) && e.keyCode === 83) {
        e.preventDefault()
        if (document.querySelectorAll('.ModalWizard__Modal').length === 0) {
          try {
            this.$modal.open(SaveScene, {
              style: {
                lightboxColor: 'rgba(0,0,0,0.6)'
              }
            })
          } catch (e) {}
        }
      }
    }.bind(this), false)

    document.addEventListener('keydown', function (e) {
      if ((e.metaKey || e.ctrlKey) && e.keyCode === 79) {
        e.preventDefault()

        const fileOpen = document.createElement('input')
        fileOpen.type = 'file'
        fileOpen.accept = '.json'

        fileOpen.addEventListener('change', (ev) => {
          const file = ev.target.files[0]
          const reader = new FileReader()

          reader.onload = e => {
            try {
              window.editor.load(JSON.parse(e.target.result))
            } catch (e) {
              alert('Invalid TSM Editor template')
              emptyScene()
            }
          }
          reader.readAsText(file)
        }, false)

        fileOpen.click()
      }
    }, false)
  },
  methods: {
    openWelcomeModal () {
      this.$modal.open(WelcomeModal, {
        style: {
          lightboxColor: 'rgba(0,0,0,0.6)'
        }
      })
    },
    openAboutModal () {
      this.$modal.open(AboutModal, {
        style: {
          lightboxColor: 'rgba(0,0,0,0.6)'
        }
      })
    },
    openReleaseNotesModal () {
      this.$modal.open(ReleaseNotes, {
        style: {
          lightboxColor: 'rgba(0,0,0,0.6)'
        }
      })
    },
    openSaveModal () {
      this.$modal.open(SaveScene, {
        style: {
          lightboxColor: 'rgba(0,0,0,0.6)'
        }
      })
    },
    openScene (ev) {
      const file = ev.target.files[0]
      const reader = new FileReader()

      reader.onload = e => {
        try {
          window.editor.load(JSON.parse(e.target.result))
        } catch (e) {
          alert('Invalid TSM Editor template')
          emptyScene()
        }
      }
      reader.readAsText(file)
    },
    importScene (ev) {
      const file = ev.target.files[0]
      const reader = new FileReader()

      reader.onload = e => {
        try {
          const currentScene = window.editor.save()
          const importedScene = JSON.parse(e.target.result)
          const currentSceneNodePosition = currentScene.nodes[0].position.y ?? 400
          /** bump nodes below current graph */
          importedScene.nodes.forEach(node => {
            node.position.y = node.position.y + currentSceneNodePosition + 400
          })
          currentScene.nodes = currentScene.nodes.concat(importedScene.nodes)
          currentScene.connections = currentScene.connections.concat(importedScene.connections)
          window.editor.load(currentScene)
        } catch (e) {
          alert('Invalid TSM Editor template')
          emptyScene()
        }
      }
      reader.readAsText(file)
    },
    openNewModal () {
      if (confirm('Are you sure? This will clear the current scene.')) {
        window.editor.load(JSON.parse('{"nodes":[{"type":"Start","id":"node_16240438894570","name":"Start","options":[],"state":{},"interfaces":[["The beginning of your string",{"id":"ni_16240438894571","value":null}]],"position":{"x":400,"y":140},"width":200,"twoColumn":false}],"connections":[],"panning":{"x":0,"y":0},"scaling":1}'))
      }
    },
    openHighMovExample () {
      if (confirm('Are you sure? This will clear your current scene')) {
        window.editor.load(HighMovExample.default)
      }
    },
    openBasicValueExample () {
      if (confirm('Are you sure? This will clear your current scene')) {
        window.editor.load(BasicValueExample.default)
      }
    },
    openAdvValueExampleExample () {
      if (confirm('Are you sure? This will clear your current scene')) {
        window.editor.load(AdvValueExample.default)
      }
    }
  }
}
</script>

<style lang="scss">
.nav {
  background: #292d37;
  width: 100%;
  display: flex;
  padding: 10px;
  box-sizing: border-box;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  color: #bfbfbf;

  .menu {
    display: flex;
  }

  .menu-item {
    background: none;
    border: none;
    display: block;
    cursor: pointer;
    margin-right: 10px;

    span {
      color: #fff;
    }

    .item {
      display: block;
      text-decoration: none;
      color: #fff;
      padding: 10px 20px;
      text-align: left;
    }

    .item:hover {
      background: #444a57;
    }
  }

  .text-reader {
    display: block;
    cursor: pointer;
  }

  .text-reader input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
  }

  .discord {
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: 12px;
  }
}
</style>
