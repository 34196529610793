<template>
  <div class="modal">
    <div class="title">
      <h1>Save Scene</h1>
    </div>
    <div class="content">
      <label>
        <span>File name</span>
        <input
          ref="name"
          type="text"
          class="dark-input"
          placeholder="Ex, MyScene"
        >
      </label>
      <button @click="saveScene()">
        Save
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SaveModal',
  methods: {
    saveScene () {
      const d = new Date()
      const datestring = ('0' + d.getDate()).slice(-2) + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' +
      d.getFullYear() + '-' + ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2)

      const name = this.$refs.name.value || 'TSMEditor-' + datestring
      const scene = JSON.stringify(window.editor.save())
      const element = document.createElement('a')

      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(scene))
      element.setAttribute('download', name + '.json')
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
      this.$modal.close()
    }
  }
}
</script>

<style lang="scss" scoped>
input {
  box-sizing: border-box;
  margin-top: 10px;
}

.dark-input {
  background: #2A2E37;

  &:hover {
    background: #454b5b;
  }
}
</style>
