import { Node } from '@baklavajs/core'

export class RoundNode extends Node {
  counter = 2
  type = 'Round'
  name = 'Round Plugin'

  constructor () {
    super()
    this.addOption('Description', 'TextOption', 'Round gold to factor')
    this.addInputInterface('Gold amount', 'InputOption', undefined, { type: 'plugin' })
    this.addOption('Factor', 'InputOption')
    this.addOutputInterface('Result', { type: 'plugin' })
  }
}
