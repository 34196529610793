import { render, staticRenderFns } from "./ReleaseNotes.vue?vue&type=template&id=1016a8d0&scoped=true&"
var script = {}
import style0 from "./ReleaseNotes.vue?vue&type=style&index=0&id=1016a8d0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../usr/lib/node_modules/@vue/cli-service-global/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1016a8d0",
  null
  
)

export default component.exports