import { Node } from '@baklavajs/core'

export class MathNode extends Node {
  constructor () {
    super()
    this.type = 'Math'
    this.name = 'Math'
    this.addOption('Description', 'TextOption', 'Perform math on values')
    this.addInputInterface('Parameter 1', 'InputOption', undefined, { type: 'plugin' })
    this.addInputInterface('Parameter 2', 'InputOption', undefined, { type: 'plugin' })
    this.addOption('Operation', 'SelectOption', 'Add ( + )', undefined, {
      items: [
        'Add ( + )',
        'Subtract ( - )',
        'Multiply ( * )',
        'Divide ( / )',
        'Exponent ( ^ )'
      ]
    })
    this.addOutputInterface('Result')
  }
}
