import { Node } from '@baklavajs/core'

export class AverageNode extends Node {
  counter = 2
  type = 'Average'
  name = 'Average Plugin'

  constructor () {
    super()
    this.addOption('Description', 'TextOption', 'Return average of all valid parameters')
    this.addInputInterface('Parameter 1', 'InputOption', undefined, { type: 'plugin' })
    this.addInputInterface('Parameter 2', 'InputOption', undefined, { type: 'plugin' })
    this.addOption('Add', 'AddOption')
    this.addOption('Remove', 'AddOption')
    this.addOutputInterface('Result', { type: 'plugin' })
  }

  load (state) {
    state.interfaces.forEach(([name, intfState]) => {
      if (name !== 'Result') {
        const intf = this.addInputInterface(name, 'InputOption', undefined, { type: 'plugin' })
        intf.id = intfState.id
      }
    })
    this.counter = state.interfaces.length - 1
    super.load(state)
  }

  action (action) {
    if (action === 'Add') {
      this.addInputInterface(`Parameter ${++this.counter}`, 'InputOption', undefined, { type: 'plugin' })
    } else if (action === 'Remove' && this.counter > 2) {
      this.removeInterface(`Parameter ${this.counter--}`)
    }
  }
}
