export const sources = {
  AccountingSources: {
    avgBuy: 'Returns the average buy price for all purchases for a particular item',
    avgSell: 'Returns the average sale price for all sales for a particular item',
    maxBuy: 'The maximum price you\'ve purchased an item for, according to your Accounting data',
    maxSell: 'The maximum price you\'ve sold an item for, according to your Accounting data',
    minBuy: 'The minimum price you\'ve purchase an item for, according to your Accounting data',
    minSell: 'The minimum price you\'ve sold an item for, according to your Accounting data',
    SmartAvgBuy: 'Your average purchase price across your last X purchases of the item, where X is the quantity of the item you currently possess. If you do not possess the item then SmartAvgBuy will be invalid.',
    NumExpires: 'An integer for the number of times an item has expired or been cancelled from the Auction House since the last time it was sold',
    SaleRate: 'A decimal number that shows your personal Sale Rate of an item i.e a SaleRate of 0.4 indicates an item has sold for you 40% of the time you have posted it'
  },

  AuctionDBSources: {
    DBMinBuyout: 'The lowest priced auction on your realms Auction House, as of the last pricing update processed from Blizzard\'s Auction House API. Note: this value is not real-time',
    DBMarket: 'A weighted 14-day average of the auctions listed for a particular item on your realms Auction House, favoring the most recent 3 days of pricing data.',
    DBHistorical: 'A 60 day average of the DBMarket value for a particular item on your realms Auction House.',
    DBRegionMarketAvg: 'The average of DBMarket across all the realms in your region for a particular item.',
    DBRegionHistorical: 'A 60 day average of DBRegionMarketAvg for a particular item in your region.',
    DBRegionSaleAvg: 'The average sale and purchase price for a particular item in your region.',
    DBRegionSaleRate: 'A decimal number that indicates the Sale Rate of a particular item averaged across all Auction Houses in your Region i.e a DBRegionSaleRate of 0.4 indicates an item will sell on average 40% of the times it is posted per Auction House in your Region',
    DBRegionSoldPerDay: 'A decimal number indicating the volume of an item that is sold on average per Auction House per day in your Region'
  },

  OperationSources: {
    AuctioningOpMax: 'Return a numerical quantity in gold of the Maximum post price generated from the Auctioning Operation assigned to your group',
    AuctioningOpMin: 'Return a numerical quantity in gold of the Minimum post price generated from the Auctioning Operation assigned to your group',
    AuctioningOpNormal: 'Return a numerical quantity in gold of the Normal post price generated from the Auctioning Operation assigned to your group',
    ShoppingOpMax: 'Returns a gold numerical quantity of the Maximum Shopping Price from the Shopping operation assigned to your group',
    SniperOpMax: 'Returns a gold numerical quantity of the Maximum Sniper Price from the Sniper operation assigned to your group'
  },

  ExternalSources: {
    AucAppraiser: 'Auctioneer - Appraiser source. Requires the Auctioneer addon.',
    AucMinBuyout: 'Auctioneer - Minimum Buyout. Requires the Auctioneer addon.',
    AucMarket: 'Auctioneer - Market Value. Requires the Auctioneer addon.',
    AtrValue: 'Auctionator - Auction Value. Requires the Auctionator addon.',
    TUJRecent: 'TUJ 3-Day Price. Requires the TheUndermineJournal addon.',
    TUJMarket: 'TUJ 14-Day Price. Requires the TheUndermineJournal addon.',
    TUJGlobalMean: 'TUJ Global Mean. Requires the TheUndermineJournal addon.',
    TUJGlobalMedian: 'TUJ Global Median. Requires the TheUndermineJournal addon.',
    BBGRecent: 'BBG 3-Day Price. Requires the BootyBayGazette addon.',
    BBGMarket: 'BBG 14-Day Price. Requires the BootyBayGazette addon.',
    BBGGlobalMean: 'BBG Global Mean. Requires the BootyBayGazette addon.',
    BBGGlobalMedian: 'BBG Global Median. Requires the BootyBayGazette addon.',
    AHDBMinBuyout: 'AHDB Minimum Buyout. Requires the AuctionDB addon.',
    AHDBMinBid: 'AHDB Minimum Bid. Requires the AuctionDB addon.'
  },

  CoreSources: {
    Crafting: 'The sum of the material prices (matPrice) of each individual item used to craft the recipe. An item can only have a matPrice if you have a recipe that uses the item as a material. You can not reference the Crafting cost of an item if you do not have the recipe.',
    matPrice: 'The crafting material cost',
    Destroy: 'The value of the items you would receive by destroying it, on average',
    vendorBuy: 'The cost of a particular item you can purchase from a vendor. Note that vendorBuy is only populated for a particular item when you visit a vendor that can sell it',
    vendorSell: 'The value of a particular item when you sell it to a vendor',
    ItemLevel: 'The item Level of an item',
    ItemQuality: 'The item Quality of an item',
    RequiredLevel: 'The required Level of an item',
    NumInventory: 'An integer indicating the number of an item you currently possess'
  }
}
