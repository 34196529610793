export const map = {
  Average: 'avg',
  Check: 'check',
  Convert: 'convert',
  First: 'first',
  Max: 'max',
  Min: 'min',
  RoundDown: 'rounddown',
  Round: 'round',
  RoundUp: 'roundup'
}
