import { Node } from '@baklavajs/core'

export class ConvertNode extends Node {
  type = 'Convert'
  name = 'Convert Plugin'

  constructor () {
    super()
    this.addOption('Description', 'TextOption', 'Return lowest cost of all possible conversions')
    this.addInputInterface('Parameter 1', 'InputOption', undefined, { type: 'plugin' })
    this.addOption('Item (optional)', 'InputOption')
    this.addOutputInterface('Result', { type: 'plugin' })
  }
}
