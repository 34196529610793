<template>
  <div style="height: 100vh; width: 100vw; overflow: hidden">
    <nav-bar />
    <baklava-editor :plugin="viewPlugin" />
    <hint />
    <string-preview />
    <footer-bar />
  </div>
</template>

<script>
/** Templates */
import NavBar from './components/NavBar.vue'
import FooterBar from './components/FooterBar.vue'
import Hint from './components/Hint.vue'
import StringPreview from './components/StringPreview.vue'

/** Core */
import { Editor } from '@baklavajs/core'
import { ViewPlugin } from '@baklavajs/plugin-renderer-vue'
import { OptionPlugin } from '@baklavajs/plugin-options-vue'
import { Engine } from '@baklavajs/plugin-engine'
import { InterfaceTypePlugin } from '@baklavajs/plugin-interface-types'
import TextAreaOption from './plugins/TextAreaOption'
import AddOption from './plugins/AddOption'

/** Functions */
import { GreaterThanNode } from './nodes/functions/GreaterThanNode'
import { GreaterThanOrEqualToNode } from './nodes/functions/GreaterThanOrEqualToNode'
import { LessThanNode } from './nodes/functions/LessThanNode'
import { LessThanOrEqualToNode } from './nodes/functions/LessThanOrEqualToNode'

/** Plugins */
import { AverageNode } from './nodes/plugins/AverageNode'
import { CheckNode } from './nodes/plugins/CheckNode'
import { ConvertNode } from './nodes/plugins/ConvertNode'
import { FirstNode } from './nodes/plugins/FirstNode'
import { MaxNode } from './nodes/plugins/MaxNode'
import { MinNode } from './nodes/plugins/MinNode'
import { RoundDownNode } from './nodes/plugins/RoundDownNode'
import { RoundNode } from './nodes/plugins/RoundNode'
import { RoundUpNode } from './nodes/plugins/RoundUpNode'

/** Sources */
import { SourceNodeBootstrapper } from './nodes/sources/SourceNodeBootstrapper'
import { sources } from './nodes/sources/list'

/** function maps */
import { map as FunctionMap } from './nodes/functions/map'
import { map as PluginMap } from './nodes/plugins/map'

/** special */
import { SetPriceNode } from './nodes/SetPriceNode'
import { StartNode } from './nodes/StartNode'
import { CommentNode } from './nodes/CommentNode'
import { MathNode } from './nodes/MathNode'
import WelcomeModal from './components/modals/WelcomeModal.vue'

/** vars */
const intfTypePlugin = new InterfaceTypePlugin()
intfTypePlugin.addType('plugin', '#8e8e8e')
intfTypePlugin.addType('function', '#fff')

export default {
  components: { NavBar, FooterBar, Hint, StringPreview },
  data () {
    return {
      editor: new Editor(),
      viewPlugin: new ViewPlugin(),
      engine: new Engine(true)
    }
  },
  mounted () {
    this.$modal.open(WelcomeModal, {
      style: {
        lightboxColor: 'rgba(0,0,0,0.6)'
      }
    })
  },
  created () {
    /** core */
    this.editor.use(this.viewPlugin)
    this.editor.use(new OptionPlugin())
    this.editor.use(this.engine)
    this.editor.use(intfTypePlugin)
    this.viewPlugin.enableMinimap = true
    this.viewPlugin.registerOption('AddOption', AddOption)
    this.viewPlugin.registerOption('TextAreaOption', TextAreaOption)

    /** functions */
    this.editor.registerNodeType('GreaterThan', GreaterThanNode, 'Functions')
    this.editor.registerNodeType(
      'GreaterThanOrEqualTo',
      GreaterThanOrEqualToNode,
      'Functions'
    )
    this.editor.registerNodeType('LessThan', LessThanNode, 'Functions')
    this.editor.registerNodeType(
      'LessThanOrEqualTo',
      LessThanOrEqualToNode,
      'Functions'
    )

    /** plugins */
    this.editor.registerNodeType('Average', AverageNode, 'Plugins')
    this.editor.registerNodeType('Check', CheckNode, 'Plugins')
    this.editor.registerNodeType('Convert', ConvertNode, 'Plugins')
    this.editor.registerNodeType('First', FirstNode, 'Plugins')
    this.editor.registerNodeType('Max', MaxNode, 'Plugins')
    this.editor.registerNodeType('Min', MinNode, 'Plugins')
    this.editor.registerNodeType('RoundDown', RoundDownNode, 'Plugins')
    this.editor.registerNodeType('Round', RoundNode, 'Plugins')
    this.editor.registerNodeType('RoundUp', RoundUpNode, 'Plugins')

    /** special */
    this.editor.registerNodeType('Math', MathNode)
    this.editor.registerNodeType('Comment', CommentNode)
    this.editor.registerNodeType('SetPrice', SetPriceNode)
    this.editor.registerNodeType('Start', StartNode)

    /** sources */
    // eslint-disable-next-line no-unused-vars
    for (const [rKey, rValue] of Object.entries(sources)) {
      for (const [key, value] of Object.entries(rValue)) {
        const name = String(rKey).replace('So', ' So')
        this.editor.registerNodeType(
          key,
          new SourceNodeBootstrapper(`${name}: ${key}`, value),
          name
        )
      }
    }

    this.addNodeWithCoordinates(StartNode, 400, 140)
    this.engine.calculate()

    window.editor = this.editor

    /** add classes to node categories */
    const functions = [
      'GreaterThan',
      'GreaterThanOrEqualTo',
      'LessThan',
      'LessThanOrEqualTo'
    ]

    const plugins = [
      'Average',
      'Check',
      'Convert',
      'First',
      'Max',
      'Min',
      'RoundDown',
      'Round',
      'RoundUp'
    ]

    this.viewPlugin.hooks.renderNode.tap(this, (node) => {
      if (functions.includes(node.data.type)) {
        node.$el.classList.add('function')
      }

      if (plugins.includes(node.data.type)) {
        node.$el.classList.add('plugin')
      }

      if (node.data.name.includes('Source')) {
        node.$el.classList.add('source')
      }

      return node
    })

    /** listen to events so we can render our string */
    const events = [
      'addNode',
      'removeNode',
      'addConnection',
      'removeConnection',
      'usePlugin'
    ]

    events.forEach(name => {
      this.editor.events[name].addListener(this, () => {
        setTimeout(() => {
          listenForInput('input', 'input')
          listenForInput('.__dropdown .item', 'click')
          listenForInput('button', 'click', () => {
            listenForInput('input', 'input')
          })
          hidePricePercentUnlessPluginConnected()
        })
        updateString()
      })
    })

    function listenForInput (node, eventType, callback) {
      document.querySelector('.node-container').querySelectorAll(node).forEach(elem => {
        if (elem.getAttribute('listener') !== 'true') {
          elem.setAttribute('listener', 'true')
          elem.addEventListener(eventType, () => {
            updateString()
            if (callback) {
              callback()
            }
          })
        }
      })
    }

    function hidePricePercentUnlessPluginConnected () {
      document.querySelector('.node-container').querySelectorAll('.--type-SetPrice').forEach(elem => {
        const optionsNode = elem.querySelector('.__options')
        if (elem.querySelectorAll('.__inputs .--input')[1].classList.contains('--connected')) {
          optionsNode.style.display = 'block'
        } else {
          optionsNode.style.display = 'none'
          optionsNode.querySelector('.dark-input').value = ''
          optionsNode.querySelector('.dark-input').dispatchEvent(new Event('input'))
        }
      })
    }

    /**
     * TODO: Move to new function and clean
     *
     * Right now this is basically a shot-for-shot port from the original program
     * so right now I'm emulating the same data structure and then converting into
     * the string, which while certainly not ideal - has proved a good enough
     * system for our MVP. Later versions we will simplify this and use the current
     * data structure available to us instead of this double-conversion that is
     * currently going on.
     */
    function updateString () {
      const scene = window.editor.save()
      const minimalObject = {}
      const interfaceMap = {}
      const rawStrings = {}
      const lookupTable = {
        'Add ( + )': '+',
        'Subtract ( - )': '-',
        'Multiply ( * )': '*',
        'Divide ( / )': '/',
        'Exponent ( ^ )': '^'
      }
      let startNodeId = ''
      let beginningNode = ''

      /** BEGIN CONVERSION TO EMULATE DATA STRUCTURE */
      scene.nodes.forEach(node => {
        node.interfaces.forEach(nInterface => {
          interfaceMap[nInterface[1].id] = node.id

          if (node.name.includes('Start')) {
            startNodeId = nInterface[1].id
          }
        })
      })

      scene.connections.forEach(connection => {
        if (connection.from === startNodeId) {
          beginningNode = interfaceMap[connection.to]
        }
      })

      scene.nodes.forEach(node => {
        minimalObject[node.id] = {}

        if (node.name.includes('Function')) {
          minimalObject[node.id].function = FunctionMap[node.type]
        } else if (node.name.includes('Plugin')) {
          minimalObject[node.id].function = PluginMap[node.type]
        } else if (node.name.includes('Source')) {
          rawStrings[node.id] = node.type
        } else if (node.name.includes('Set Price')) {
          rawStrings[node.id] = node.type
        }

        if (node.name.includes('Function') || node.name.includes('Plugin') || node.name.includes('Set Price') || node.name.includes('Math')) {
          minimalObject[node.id].name = node.name

          const params = {}
          const paths = {}

          node.interfaces.forEach(nInterface => {
            const name = nInterface[0]
            if (name.includes('Param') || name.includes('Price')) {
              scene.connections.forEach(connection => {
                if (connection.to === nInterface[1].id) {
                  params[name] = '::' + interfaceMap[connection.from] + '::'
                }
              })

              if (!params[name]) {
                params[name.replace(' ', '')] = nInterface[1].value
              }
            } else if (/True|False/.test(name)) {
              scene.connections.forEach(connection => {
                if (connection.from === nInterface[1].id) {
                  paths[name.toLocaleLowerCase()] = '::' + interfaceMap[connection.to] + '::'
                }
              })
            } else {
              scene.connections.forEach(connection => {
                if (connection.from === nInterface[1].id) {
                  paths.true = '::' + interfaceMap[connection.to] + '::'
                }
              })
            }
          })

          if (node.name.includes('Set Price')) {
            node.options.forEach(nOption => {
              params.percent = nOption[1] ?? ''
            })
          }

          if (node.name.includes('Math')) {
            node.options.forEach(nOption => {
              if (nOption[1] in lookupTable) {
                params.operator = lookupTable[nOption[1]] ?? ''
              }
            })
          }

          minimalObject[node.id].params = params
          minimalObject[node.id].paths = paths
        }
      })

      /** END CONVERSION TO EMULATE DATA STRUCTURE */

      /** BEGIN CONVERSION TO TSM STRING */
      scene.nodes.forEach(node => {
        const name = node.name
        const nodeId = node.id
        if (minimalObject[node.id] && Object.keys(minimalObject[node.id]).length > 0) {
          const currentNode = minimalObject[node.id]
          const nodeFunction = minimalObject[node.id].function

          // -> Sort params and track
          const paramKeys = Object.keys(currentNode.params)
          paramKeys.sort()
          const params = []
          for (const param of paramKeys) {
            params.push(currentNode.params[param])
          }

          // -> Sort paths and track
          const pathKeys = Object.keys(currentNode.paths)
          pathKeys.sort()
          pathKeys.reverse()
          const paths = []
          for (const path of pathKeys) {
            paths.push(currentNode.paths[path])
          }

          const paramString = params.join(',')
          const pathString = paths.join(',')

          if (name.includes('Function') && name.includes('Set Price') === false) {
            rawStrings[nodeId] = nodeFunction + '(' + paramString + ',' + pathString + ')'
          } else if (name.includes('Set Price')) {
            const price = paramString.split(',')
            price.reverse()
            rawStrings[nodeId] = price.join(' ')
          } else if (name.includes('Math')) {
            const currentParams = currentNode.params
            let raw = `(${currentParams.Parameter1 || currentParams['Parameter 1']} ${currentNode.params.operator} ${currentParams.Parameter2 || currentParams['Parameter 2']})`

            if (currentParams.operator === '^') {
              raw = raw.replace(' ^ ', '^')
            }

            rawStrings[nodeId] = raw
          } else {
            rawStrings[nodeId] = nodeFunction + '(' + paramString + ')'
          }
        }
      })

      for (const key of Object.keys(rawStrings)) {
        const name = key
        const value = rawStrings[key]

        for (const nesKey of Object.keys(rawStrings)) {
          rawStrings[nesKey] = rawStrings[nesKey].replace('::' + name + '::', value)
        }

        /** TODO: fix so we don't need a second pass */
        for (const nesKey of Object.keys(rawStrings)) {
          rawStrings[nesKey] = rawStrings[nesKey].replace('::' + name + '::', value)
        }
      }

      /** EMD CONVERSION TO TSM STRING */

      if (beginningNode) {
        document.querySelector('.tsm-string .data').innerText = rawStrings[beginningNode]
      } else {
        document.querySelector('.tsm-string .data').innerText = ' Nothing here yet, make sure to connect your start node! '
      }
    }
  },
  methods: {
    addNodeWithCoordinates (NodeType, x, y) {
      const n = new NodeType()
      this.editor.addNode(n)
      n.position.x = x
      n.position.y = y
      return n
    }
  }
}
</script>

<style lang="scss">
.node {
  width: auto !important;
  min-width: 200px !important;
  max-width: 260px !important;
  border-radius: 2px !important;
  background: #2f343f !important;
  filter: none !important;

  &[class*="--type-"] {
    &.--selected {
      box-shadow: 0 0 0 1px #712fc1;
    }

    .__title {
      background: #712fc1;
      border-radius: 2px 2px 0 0;
    }
  }

  &.plugin {
    &.--selected {
      box-shadow: 0 0 0 1px #32ad59;
    }

    .__title {
      background: #32ad59;
    }
  }

  .__options {
    order: 0;
  }

  &.function {
    &.--selected {
      box-shadow: 0 0 0 1px #2f61c1;
    }

    .__title {
      background: #2f61c1;
    }
  }

  &.source {
    &.--selected {
      box-shadow: 0 0 0 1px #755143;
    }

    .__title {
      background: #755143;
    }
  }

  &.--type-SetPrice {
    .__options {
      order: 999;
    }

    .dark-input {
      margin-top: 0;
    }
  }

  &.--type-Start {
    .--output {
      text-align: left;
    }
  }

  &.--type-Comment {
    width: 240px !important;
  }

  &.--type-MathNode {
    .dark-select {
      margin-top: 10px;
      margin-bottom: 5px;
    }
  }
}

.node-editor {
  .background {
    background-color: #21242d !important;
    background-image: linear-gradient(transparent 0, transparent 0),
      linear-gradient(90deg, transparent 0, transparent 0),
      linear-gradient(#252933 1px, transparent 0px),
      linear-gradient(90deg, #252933 1px, transparent 0) !important;
  }

  .dark-input,
  .dark-select,
  .dark-select .__selected,
  .dark-num-input,
  .dark-num-input .__content,
  .dark-num-input .__button {
    background-color: #292d37;

    &:hover,
    &:active {
      background-color: #383d4a;
    }
  }
}

.__content {
  display: flex;
  flex-direction: column;
}

.__inputs .__content {
  flex-direction: row;
}

.__outputs {
  order: 9999;
}

.paramBttn {
  width: calc(50% - 2px);
  display: inline-block;
  border: none;
  padding: 8px;
  background: #454b5b;
  color: white;
  border-radius: 3px;
  margin: 5px 0;
}

.node-interface {
  padding-left: 0 !important;
}

.node-option .dark-input {
  margin-top: 10px;
}

.submenu,
.dark-context-menu.--nested {
  min-width: 170px;
}

.node-container {
  width: 9999% !important;
}

textarea {
  resize: none;
}

.minimap {
  max-width: 300px !important;
}
</style>
